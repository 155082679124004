import Udit from './uditmain.jpeg'
import Udit_SuperMan from './udit_jain_superhero.png'
import Udit_professional from './pfp_old.jpg'
import UditJain_SuperMan from './uditjain_superher_main.jpg'
import mailpic from './mailpic.webp'
function Intro() {


    return (
        <div className="flex flex-col items-center gap-2 mt-[-50px] ">
            {/* <img src={photo} className='z-[-12] absolute h-[60vw] top-[340px] opacity-20'></img> */}
            <div className=' lg:hidden flex flex-row justify-center mb-[-10px] mx-auto'>
            <img src={Udit_professional} alt='Udit Jains photo|udit jain|udit jain newton school of technology' className="h-[5rem] w-[5rem] rounded-full border-4 mt-[-10px] ml-4 animate-scale object-cover"></img>
            </div>

            <h1 className='font-medium  text-2xl md:text-3xl lg:text-4xl text-transparent bg-gradient-to-r from-gray-100 to-gray-400 bg-clip-text  tracking-wider animate-jump mt-8 lg:mb-[0px] mb-[-3px] ' >Hello, I'm</h1>

            <div className='flex flex-row justify-center mx-auto mt-3'>
                <h1 className='text-3xl md:text-5xl lg:text-[3.9em]  text-transparent bg-gradient-to-r from-gray-100 to-gray-400 bg-clip-text font-bold tracking-wider animate-slide2 lg:animate-slide'>Udit Jain</h1>
                <img src={Udit_professional}   alt='Udit Jains photo|udit jain|udit jain newton school of technology' className=" lg:block rounded-full border-4  w-[5.4rem] h-[5.4rem] border-gray-400 mt-[-10px] ml-7 hidden lg:animate-scale object-cover "></img>
            </div>

            <div className='animate-jump'>
                <h1 className=' md:text-4xl lg:text-5xl text-transparent bg-gradient-to-t from-white mt-2 to-gray-200 bg-clip-text -tracking-tighter whitespace-nowrap font-semibold '>A Skilled Full Stack Developer</h1>
                <span >
                    <svg xmlns="https://www.w3.org/2000/svg" className=' lg:w-[14.3rem] lg:h-[1.8rem] h-[0.363rem]'   viewBox="0 0 235 17" fill="none">
                        <path d="M33.297 1.90382C76.5 -0.425974 136.5 1.00003 209.393 4.73854" stroke="white" stroke-width="0.75" stroke-linecap="round" />
                        <path d="M209.393 5.01981C152.617 3.17279 32.6818 3.00977 1 10.8904" stroke="white" stroke-width="0.75" stroke-linecap="round" />
                        <path d="M1 11.0536C18.5 7.13799 146.962 2.51236 234 16" stroke="white" stroke-width="0.75" stroke-linecap="round" />
                    </svg>
                </span>
            </div>


        </div>
    )
}

function Socials() {

    return (
        <div className='flex flex-col justify-center items-center gap-2 mt-12 animate-fall '>
            <div className='flex flex-col lg:flex-row gap-8 h-min items-center'>
                {/* <a class="border text-xl border-zinc-600 lg:border-zinc-800 outline-none text-gray-50 py-1 px-5 flex items-center  gap-2 rounded-full lg:hover:scale-[1.05] lg:hover:border-zinc-500" href="#contact">Got a project ? Let's talk</a> */}
                <a aria-label="button" class="border text-base lg:text-xl  border-zinc-600 lg:border-zinc-800 outline-none text-gray-50 py-1 px-5 flex items-center  gap-2 rounded-full lg:hover:scale-[1.05] lg:hover:border-zinc-500" href='#contact' ><button class="px-7 py-3 rounded-full relative radial-gradient group hover:scale-105" tabindex="0" ><span class="text-gray-50 lg:group-hover:scale-105  lg:active:scale-105 transition group flex items-center gap-3 tracking-wide h-full w-full relative linear-mask">Got a project ? Let's talk<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" class="opacity-70 -rotate-45 lg:group-hover:translate-x-0.5  lg:group-hover:-translate-y-0.5 transition" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path></svg></span><span class="block absolute inset-0 rounded-full p-px lg:group-hover:scale-105  linear-overlay"></span></button></a>
                <a aria-label="button" class="border text-base lg:text-xl border-zinc-600 lg:border-zinc-800 outline-none text-gray-50 py-1 px-5 flex items-center  gap-2 rounded-full lg:hover:scale-[1.05] lg:hover:border-zinc-500" target='_blank' href="/Udit_Jain's_Resume.pdf" download=""><button class="px-7 py-3 rounded-full relative radial-gradient group hover:scale-105" tabindex="0" ><span class="text-gray-50 lg:group-hover:scale-105  lg:active:scale-105 transition group flex items-center gap-3 tracking-wide h-full w-full relative linear-mask">Download CV<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 20 20" aria-hidden="true" class="opacity-70 lg:group-hover:translate-y-0.5 transition" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg></span><span class="block absolute inset-0 rounded-full p-px lg:group-hover:scale-105  linear-overlay"></span></button></a>
                {/* <a className='text-white text-xl border-2 border-white rounded-full px-4 py-2 hover:bg-white hover:text-black transition-all duration-300'>Got a project? Let's talk</a> */}
            </div>

            <Socials2/>
            
        </div>


    )
}
export function Socials2(){
    return (

            <div className='flex flex-row mt-10 gap-6 '>
            <a target="_blank" aria-label="button" href='https://linkedin.com/in/uditjain13' rel="noopener noreferrer" id="socials" class="flex items-center gap-2 p-4 text-gray-700 rounded-full   lg:hover:scale-[1.25] transition  group border lg:group-hover:bg-custom-gradient lg:p-6 border-black/10 bg-white/15 text-white/60"> <span class=""><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"></path></svg></span></a>
            <a target='_blank' aria-label="button" href='https://github.com/uditjainstjis' rel="noopener noreferrer" id="socials"  class="flex items-center gap-2 p-4 text-gray-700 rounded-full  lg:hover:scale-[1.25] transition  group border lg:group-hover:bg-custom-gradient lg:p-6 border-black/10 bg-white/15 text-white/60"> <span class=""><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"></path></svg></span></a>
            <a href='mailto:uditj668@gmail.com' id="socials"  class="flex items-center gap-2 p-4 text-gray-700 rounded-full  lg:group-hover:scale-[1.25] transition  group border lg:hover:scale-[1.25] bg-custom-gradient lg:p-6 border-black/10 bg-white/15 text-white/60"> <span class=""><img  alt="linkedIn" className='h-3 ' src={mailpic}></img></span></a>
            
            </div> 

    )
}
export function Front() {

    return (
        <section className="w-full h-screen flex flex-col justify-center items-center" aria-label='Udit Jain studies at Newton School Of Technology|udit jani nst'>
            <Intro />
            <Socials />
        </section>

    )
}

